import React, { useMemo } from 'react'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { Box, chain, Flex, Icon, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Id } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { HiringStageJobPostings } from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/HiringStageJobPostings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { getMainJobPosting } from '@src/pages/Forms/Candidate/utils'

type OptionType = InterviewRoundInterface | Id<'new'>

type SpecialisationHiringStageProps = {
  item: InterviewRoundInterface
  isMainRound: boolean
}

const SpecialisationHiringStage = ({
  item,
  isMainRound,
}: SpecialisationHiringStageProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const baseInterviewRoundOnJobPosting = featureFlags.includes(
    FeatureFlags.BaseInterviewRoundOnJobPosting,
  )
  const mainJobPosting = getMainJobPosting(item)
  if ('archived_reason' in item) {
    const specialisationName = item.specialisation.name
    const jobPostingName = mainJobPosting?.job_posting?.name
    const opportunityTitle = baseInterviewRoundOnJobPosting
      ? jobPostingName || specialisationName
      : specialisationName
    return (
      <>
        <Text
          variant="h6"
          color={item.archived_reason ? Token.color.greyTone50 : undefined}
        >
          {chain(
            opportunityTitle,
            isMainRound && <Text color={Token.color.green}>Main</Text>,
            !!item.archived_reason && <Text>Archived</Text>,
          )}
        </Text>
        {item.archived_reason && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {chain(
                item.latest_interview_stage_updated_date_time &&
                  formatDate(item.latest_interview_stage_updated_date_time),
                item.archived_reason.name,
              )}
            </Text>
          </Box>
        )}
        {item.latest_interview_stage && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {item.latest_interview_stage.interview_type_display}&nbsp;
              {!!item.stage_count &&
                Number.isFinite(item.latest_interview_stage.interview_number) &&
                `(${item.latest_interview_stage.interview_number}/${item.stage_count})`}
            </Text>
          </Box>
        )}
        {baseInterviewRoundOnJobPosting ? (
          jobPostingName && (
            <Box>
              <Text variant="small" color={Token.color.greyTone50}>
                Specialisation: {specialisationName}
              </Text>
            </Box>
          )
        ) : (
          <HiringStageJobPostings
            round={item}
            textVariant="small"
            labelVariant="small"
            textColor={Token.color.greyTone50}
          />
        )}
      </>
    )
  }
  return (
    <Flex alignItems="center" color={Token.color.blue}>
      <Icon name="Plus" size={16} color={Token.color.blue} />
      &nbsp;Consider for another opportunity
    </Flex>
  )
}

type Props = {
  candidateId: number
  roundId: number
  mainRoundId?: number
  onChange: (roundId: number) => void
}

const mapRoundToOption = (
  item: InterviewRoundInterface,
  baseInterviewRoundOnJobPosting: boolean,
) => {
  const mainJobPosting = getMainJobPosting(item)
  const label = baseInterviewRoundOnJobPosting
    ? mainJobPosting?.job_posting?.name
    : item?.specialisation?.name
  return {
    id: item.id,
    label,
    value: {
      ...item,
      label,
    },
  }
}

const getReferenceUrl = (option: OptionType, baseInterviewRoundOnJobPosting: boolean) => {
  if (option?.id === 'new') {
    return undefined
  }
  if (baseInterviewRoundOnJobPosting) {
    const mainJobPosting = getMainJobPosting(option)
    return mainJobPosting && option?.specialisation
      ? pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
          specId: option.specialisation.id,
          id: mainJobPosting?.id,
        })
      : undefined
  }
  return option?.specialisation
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
        id: option.specialisation.id,
      })
    : undefined
}

export const HiringStageSwitcher = ({
  candidateId,
  roundId,
  mainRoundId,
  onChange,
}: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const baseInterviewRoundOnJobPosting = featureFlags.includes(
    FeatureFlags.BaseInterviewRoundOnJobPosting,
  )
  const { data, isLoading } = useGetFullInterviewRounds(candidateId || null)

  const options = useMemo<RadioSelectOption<OptionType>[]>(() => {
    const list = (data || []).reduce<{
      active: InterviewRoundInterface[]
      archived: InterviewRoundInterface[]
    }>(
      (acc, item) => {
        if (item.archived_reason) {
          acc.archived.push(item)
        } else {
          acc.active.push(item)
        }

        return acc
      },
      { active: [], archived: [] },
    )

    const active = list.active.map(item =>
      mapRoundToOption(item, baseInterviewRoundOnJobPosting),
    )
    const archived = list.archived.map(item =>
      mapRoundToOption(item, baseInterviewRoundOnJobPosting),
    )
    return [...active, { id: 'new', value: { id: 'new' } }, ...archived]
  }, [data])

  const optionValue = options?.find(item => item.value.id === roundId)?.value

  return (
    <RadioSelectInput<OptionType>
      label="Opportunity"
      labelPath="label"
      value={optionValue}
      searchable={false}
      onChange={item => {
        if (item && 'specialisation' in item) {
          onChange(item.id)
        } else {
          navigateTo(
            pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
              candidateId,
            }),
          )
        }
      }}
      loading={isLoading}
      disabled={isLoading}
      options={options}
      allowCreateNewOption
      referenceUrl={
        optionValue
          ? getReferenceUrl(optionValue, baseInterviewRoundOnJobPosting)
          : undefined
      }
    >
      {option => (
        <SpecialisationHiringStage
          item={option.value as InterviewRoundInterface}
          isMainRound={mainRoundId === option.value.id}
        />
      )}
    </RadioSelectInput>
  )
}
